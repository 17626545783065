import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  allPriceForecastScenario,
  getAllBasinByScenario,
  getAllFuelPriceByBasin,
  getAllFuelTypes
} from "../api/data";
import { setPricescenarioData } from "../redux/reducers/selectedPriceScenario";
import SelectBox from "./SelectBox";
import RefinePriceChart from "./charts/RefinePriceChart";
import {
  setFuelFamily,
  setFuelOrigin,
  setBasinsList,
} from "../redux/reducers/selectedtemplateData";

import ErrorOverlaymodal from "../components/ErrorOverlayModal";

const RefinePriceTab = ({ handlePrevious, selectedBasin, setSelectedBasin, chartData, setChartData, loading, setLoading }) => {
  const dispatch = useDispatch();

  const priceScenario = useSelector(
    (state) => state.selectedPriceScenario.priceScenario
  );

  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  const [priceScenarios, setPriceScenarios] = useState([]);
  const [showPriceScenarioError, setShowPriceScenarioError] = useState(false);
  const [basins, setBasins] = useState([]);
  const [showBasinError, setShowBasinError] = useState(false);
  const [allFuelData, setAllFuelData] = useState([]);

  const [fuelFamiles, setFuelFamiles] = useState([]);
  const [showFuelFamilesError, setShowFuelFamilesError] = useState(false);
  const [fuelOrigins, setFuelOrigins] = useState([
    { id: 0, item: "fossil" },
    { id: 1, item: "bio" },
    { id: 2, item: "synth" },
  ]);
  const [checkedPrice, setCheckedPrice] = useState(true);
  const [label, setLabel] = useState("Price by MMBtu (LHV)");
  const [checkedItemsFuelOrigin, setCheckedItemsFuelOrigin] = useState([]);
  const [fuelTypeName, setFuelTypeName] = useState({});
  const basinData = useSelector((state) => state.selectedBasin);
  const [showFuelOriginError, setShowFuelOriginError] = useState(false);


  const { fuelFamily, basePrice, fuelOrigin, inflationRate, priceUnit, findFuelType, basinList } =
    useSelector((state) => state.selectedtemplateData);

  useEffect(() => {
    if (checkedPrice === true) {
      setLabel("Price by MMBtu (LHV)");
    } else {
      setLabel("Price by Mt");
    }
  }, [checkedPrice]);

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  const handleFuelFamily = (item) => {
    dispatch(setFuelFamily(item));
  };

  const handleChangeFuelOrigin = (item) => {
    dispatch(setFuelOrigin(item));
  };

  const getAllPriceScenarios = () => {
    allPriceForecastScenario()
      .then((res) => {
        const scenario = res.data.map((s, index) => ({
          id: s?.id,
          item: s?.scenarioName,
        }));
        setPriceScenarios(scenario);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("error in price forcast scenario", err);
      });
  };

  const getAllFuelList = () => {
    getAllFuelTypes()
      .then((res) => {
        const data = res.data;
        setAllFuelData(data);
        const uniqueFuelFamilyNames = new Set();

        Object.keys(data).forEach((key) => {
          if (data[key].family?.visibleInPriceEditor) {
            const fuelFamilyName = data[key].family?.fuelFamilyName;
            if (fuelFamilyName) {
              uniqueFuelFamilyNames.add(fuelFamilyName);
            }
          }
        });

        const list = [...uniqueFuelFamilyNames].map((item, index) => ({
          id: index + 1,
          item: item,
        }));
        setFuelFamiles(list);
      })
      .catch((err) => {
        setError(error);
        setOpen(true);
        console.log("error get all fuel types", err);
      });
  };

  const getBasinsForScenario = (value) => {
    const id = parseInt(value);
    getAllBasinByScenario(id)
      .then((res) => {
        const data = res.data;
        const list = data.map((item) => ({
          id: item.id,
          item: item.basinName,
        }));
        dispatch(setBasinsList(list));
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
      });
  }

  useEffect(() => {
    if (priceScenario && priceScenario.id) {
      getBasinsForScenario(priceScenario.id);
    }
  }, [priceScenario]);

  useEffect(() => {
    if (priceScenario && priceScenario.id) {
      if (basinList.length > 0) {
        const validBasin = basinList.find((b) => b.id === selectedBasin?.id);
        setSelectedBasin(validBasin || { id: null, item: "Select" }); // Clear selection if invalid
      } else {
        setSelectedBasin({ id: null, item: "Select" }); // No basins available, clear selection
      }
    }
  }, [basinList, priceScenario]);



  useEffect(() => {
    getAllPriceScenarios();
    getAllFuelList();
  }, []);

  const handleScenarioChange = (item) => {
    dispatch(setPricescenarioData(item));
  };

  const handleChangeBasin = (item) => {
    setSelectedBasin(item);
  };

  const getFuelPrice = () => {
    const normalizeByLHV = checkedPrice;
    if (
      priceScenario.id !== null &&
      selectedBasin.id !== null &&
      fuelFamily.id !== null &&
      checkedItemsFuelOrigin.length > 0
    ) {
      setLoading(true);
      getAllFuelPriceByBasin(priceScenario.id, selectedBasin.id)
        .then((res) => {
          setChartData(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setError(err);
          setOpen(true);
          console.log("get all fuel prices by basin error", err);
        });
    }
  };

  useEffect(() => {
    getFuelPrice();
  }, [checkedPrice, priceScenario, selectedBasin, fuelFamily, checkedItemsFuelOrigin]);

  const handleCheckboxChangeFuels = (option) => {
    if (checkedItemsFuelOrigin.includes(option)) {
      setCheckedItemsFuelOrigin(
        checkedItemsFuelOrigin.filter((item) => item !== option)
      );
    } else {
      setCheckedItemsFuelOrigin([...checkedItemsFuelOrigin, option]);
    }
  };

  const handleLegislativeScenarioChange = (item) => {
    dispatch(setPricescenarioData(item));
  };

  useEffect(() => {
    if (allFuelData?.length > 0) {
      const fuelData = allFuelData.reduce((acc, item) => {
        acc[item.id] = item.fuelTypeName;
        acc[item.fuelTypeName] = item.id;
        return acc;
      }, {});
      setFuelTypeName(fuelData);
    }
  }, [allFuelData]);

  useEffect(() => {
    setCheckedItemsFuelOrigin(fuelOrigins);
  }, [fuelOrigins]);

  return (
    <div className="refine-price-container">
      <div className="refine-price-tab-header">
        <div className="action-container align-items-center w-100 d-flex flex-wrap gap-4">
          <div className="gap-4 w-fit-content d-flex flex-wrap">
            <div className="d-flex align-items-center">
              <label className="form-check-label stretch fs-14">Scenario Name</label>
              <div>
                <SelectBox
                  selectedItem={priceScenario}
                  listItems={priceScenarios}
                  setSelectedItem={(item) => handleLegislativeScenarioChange(item)}
                  disabledOptions={[priceScenario]}
                  showError={showPriceScenarioError}
                  setShowError={setShowPriceScenarioError}
                  className="select-box-v2 custom-select-box"
                  identifier='priceForecast'
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <label className="form-check-label refine-margin fs-14">{'Fuel Family'}</label>
              <div>
                <SelectBox
                  selectedItem={fuelFamily}
                  listItems={fuelFamiles}
                  setSelectedItem={(item) => handleFuelFamily(item)}
                  disabledOptions={[fuelFamily]}
                  showError={showFuelFamilesError}
                  setShowError={setShowFuelFamilesError}
                  className="select-box-v2 small-select-box"
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <label className="form-check-label refine-margin fs-14">{'Basin'}</label>
              <div>
                <SelectBox
                  selectedItem={selectedBasin}
                  listItems={basinList}
                  setSelectedItem={(item) => handleChangeBasin(item)}
                  showError={showBasinError}
                  setShowError={setShowBasinError}
                  className="select-box-v2 small-select-box"
                />
              </div>
            </div>
          </div>
          <div className="priceby-toggle">
            <div className="fuel-origin ms-0">
              {fuelOrigins.map((o, i) => (
                <div className="form-check form-check-inline" key={o.id}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={o.id}
                    onChange={() => handleCheckboxChangeFuels(o)}
                    checked={checkedItemsFuelOrigin.includes(o)}
                  />
                  <label className="form-check-label" htmlFor="inlineCheckbox1">
                    {o.item}
                  </label>
                </div>
              ))}
            </div>
            <div className="d-flex">
              <div className="form-check form-switch ms-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckChecked"
                  onChange={(e) => setCheckedPrice(e.target.checked)}
                  checked={checkedPrice}
                />
              </div>
              <div className="d-flex">
                <label className="form-check-label pr-4">{label}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <RefinePriceChart
        checkedPrice={checkedPrice}
        chartDatas={chartData}
        fuelOrigin={checkedItemsFuelOrigin}
        fuelTypeName={fuelTypeName}
        loading={loading}
        fuelFamily={fuelFamily}
        allFuelData={allFuelData}
        selectedBasin={selectedBasin}
        handlePrevious={handlePrevious}
      />

      <ErrorOverlaymodal
        show={open}
        handleClose={handleClose}
        errorMessage={
          error
            ? error.response
              ? error.response.data.message
              : "Unknown error occurred"
            : "No error"
        }
      />
    </div>
  );
};

export default RefinePriceTab;
