import React, { useState, useRef, useEffect } from "react";

import "../css/main.css";

import ArrowUp from "../assets/arrowUpIcon.svg";
import ArrowDown from "../assets/arrowDownIcon.svg";
import { useDispatch } from "react-redux";
import {
  addFuelPriceScenario,
  addLegislativeScenario,
  allLegislativeScenario,
  allPriceForecastScenario,
} from "../api/data";
import { setLegislativeScenarioData } from "../redux/reducers/selectedLegislativeScenario";
import { setPricescenarioData } from "../redux/reducers/selectedPriceScenario";
import {
  setFuelFamily,
  setBasePrice,
  setInflationRate,
  setFuelOrigin,
  setPriceUnit,
} from "../redux/reducers/selectedtemplateData";
import { toast } from "react-toastify";
import EditBtnIcon from "../assets/editbtn.svg";

const SelectBox = ({
  listItems,
  showError,
  selectedItem,
  setSelectedItem,
  setShowError,
  disabledOptions,
  className = "",
  selectBoxContainer = "",
  show = false,
  showSelect = true,
  showErrorPopup = true,
  showAddElement = false,
  showPopupOnAddElement = false,
  addElementOptionName = "",
  identifier,
  showEditIcon = false,
  setPopup = false,
  setPopupModal = "",
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const selectBoxRef = useRef(null);
  const [scenarioValue, setScenarioValue] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [legislativeScenarios, setLegislativeScenarios] = useState([]);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [priceScenarios, setPriceScenarios] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        selectBoxRef.current &&
        !selectBoxRef.current.contains(event.target)
      ) {
        setShowDropDown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleSelect = () => {
    setShowDropDown(!showDropDown);
  };

  const handleEditClick = (e, id) => {
    e.stopPropagation();
    setPopup(true);
    setPopupModal("editVesselDetails");
    setSelectedItem(id);
  };

  const handleItemClick = (item) => {
    if (item.id !== null) {
      setSelectedItem(item);
      setShowDropDown(false);
      if (showErrorPopup) {
        setShowError(false);
      }
    }
  };

  const handleInputChange = (e) => {
    setShowInput(true);
    setScenarioValue(e.target.value);
  };

  const handleClick = () => {
    if (showPopupOnAddElement) {
      setSelectedItem({
        id: null,
        item: null,
      });
      setPopup(true);
      setPopupModal("editVesselDetails");
    } else {
      setShowInput(true);
    }
  };

  const getAllPriceScenarios = () => {
    allPriceForecastScenario()
      .then((res) => {
        const scenario = res.data.map((s, index) => ({
          id: s?.id,
          item: s?.scenarioName,
        }));
        setPriceScenarios(scenario);
      })
      .catch((err) => console.log("error in price forcast scenario", err));
  };

  const addPriceScenario = (name) => {
    addFuelPriceScenario(name)
      .then((res) => {
        getAllPriceScenarios();
        console.log(res.data);
        dispatch(
          setPricescenarioData({
            id: res.data.id,
            item: res.data.scenarioName,
          })
        );
      })
      .catch((err) => console.log("add scenario err", err));
  };

  const handleAdd = (identifier) => {
    let nameStr = scenarioValue.trim().replace(/\s+/g, " ");
    console.log("nameStr", nameStr);
    if (
      !nameStr ||
      nameStr === undefined ||
      nameStr === null ||
      nameStr === "" ||
      nameStr === " "
    ) {
      toast.error("Please enter valid Scenario Name", {
        position: "top-right",
        autoClose: 8000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
        toastId: "Auth-error",
      });
      return;
    }
    setScenarioValue("");
    if (identifier === "priceForecast") {
      addPriceScenario(scenarioValue);
      dispatch(setFuelFamily({ id: null, item: "Select" }));
      dispatch(setBasePrice({ id: null, item: "Select" }));
      dispatch(setInflationRate({ id: null, item: "Select" }));
      dispatch(setFuelOrigin({ id: null, item: "Select" }));
      dispatch(setPriceUnit({ id: null, item: "/mt" }));
    } else {
      addScenario(scenarioValue);
    }
    setShowDropDown(false);
  };

  const getAllLegislativeScenario = () => {
    allLegislativeScenario()
      .then((res) => {
        const scenario = res.data.map((s, index) => ({
          id: s?.id,
          item: s?.scenarioName,
        }));
        setLegislativeScenarios(scenario);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("error in price forcast scenario", err);
      });
  };

  const addScenario = (name) => {
    addLegislativeScenario(name)
      .then((res) => {
        getAllLegislativeScenario();
        dispatch(
          setLegislativeScenarioData({
            id: res.data.id,
            item: res.data.scenarioName,
          })
        );
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("add scenario err", err);
      });
  };

  return (

    <div
      ref={selectBoxRef}
      className={`select-box-container ${selectBoxContainer}`}
    >
      {/* Main Select Box */}
      <div
        className={`select-box ${showError ? "error-input" : ""} ${className}`}
        onClick={handleSelect}
      >
        <div className="select-icon">
          <img src={showDropDown ? ArrowUp : ArrowDown} alt="" />
        </div>
        <div className="selectBox__value">{selectedItem.item}</div>
      </div>

      {showDropDown && (
        <div className="dropdown-menu-list">
          <div className="dropdown-container">

            {showSelect && (
              
                <div
                  className="init dropdown-item"
                  onClick={() =>
                    handleItemClick({
                      id: null,
                      item: showAddElement
                        ? { addElementOptionName }
                        : `Select ${show ? "Year" : ""}`,
                    })
                  }
                >
                  {showAddElement ? (
                    <div>
                      {/* First plus button (when showInput === false) */}
                      {!showInput && (
                        <div className="plus-btn">
                          <span>{addElementOptionName}</span>
                          <button className="dropdown-add-btn" disabled>
                            +
                          </button>
                        </div>
                      )}
                      {/* Second plus button (when showInput === true) */}
                      {showInput && (
                        <div className="plus-btn">
                          <input
                            type="text"
                            placeholder="Enter New Scenario Name"
                            name="scenario_name"
                            className="input-box-drop-down no-highlight"
                            value={scenarioValue}
                            disabled
                          />
                          <button className="dropdown-add-btn" disabled>
                            +
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    /* If showAddElement === false, fallback: */
                    `Select ${show ? "Year" : ""}`
                  )}
                </div>
            )}

            {listItems.map(
              (item) =>
                item?.id && (
                  <div
                    key={item?.id}
                    className={`dropdown-item cursor-pointer d-flex align-items-center justify-content-between ${selectedItem && selectedItem.id === item.id
                        ? "active"
                        : ""
                      } ${disabledOptions?.includes(item.id) ? "disabled" : ""
                      }`}
                    onClick={() => handleItemClick(item)}
                  >
                    <span>{item.item}</span>
                    {showEditIcon && (
                      <button className="dropdown-button">
                        <img
                          className="cursor-pointer"
                          src={EditBtnIcon}
                          alt=""
                          onClick={(e) => handleEditClick(e, item)}
                        />
                      </button>
                    )}
                  </div>
                )
            )}
          </div>
        </div>
      )}
    </div>

  );
};

export default SelectBox;