import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import NavbarComponent from "../components/Navbar";
import SelectBox from "../components/SelectBox";
import {
  allLegislativeScenario,
  getOneScenario,
  getOwner,
  updateScenario,
} from "../api/data";
import { setLegislativeScenarioData } from "../redux/reducers/selectedLegislativeScenario";

import ImoTabContent from "../components/ImoTabContent";
import EuTabContent from "../components/EuTabContent";
import Loading from "../components/Loading";
import BackArrow from "../assets/arrow-left.svg";
import Years from "../utils/Years.json";

const LegislativeScenario = () => {
  const dispatch = useDispatch();
  const legislativeScenario = useSelector(
    (state) => state.selectedLegislativeScenario.legislativeScenario
  );

  const [selectedScenarioData, setSelectedScenarioData] = useState(null);
  const [zFactorValue, setZFactorValue] = useState(0.04);
  const [imoLevyAmount, setImoLevyAmount] = useState("");
  const [imoPenalty, setImoPenalty] = useState("");
  const [startYear, setStartYear] = useState({ id: null, item: "Select year" });
  const [showYearError, setShowYearError] = useState(false);
  const [years, setYears] = useState(Years);

  const [euPenalty, setEuPenalty] = useState("");
  const [euBaseYear, setEuBaseYear] = useState(null);
  const [euCostEur, setEuCostEur] = useState("");
  const [euCostCagr, setEuCostCagr] = useState(null);
  const [baseYear, setBaseYear] = useState({ id: null, item: "Select year" });
  const [showBaseYearError, setShowBaseYearError] = useState(false);

  const [legislativeScenarios, setLegislativeScenarios] = useState([]);
  const [showLegislativeError, setShowLegislativeError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ownerInfo, setOwnerInfo] = useState(null);

  const [error, setError] = useState("");
  const [errorPenalty, setErrorPenalty] = useState("");
  const [errorLevyAmount, setErrorLevyAmount] = useState("");
  const [euPenaltyError, setEuPenaltyError] = useState("");
  const [euCostError, setEuCostError] = useState("");
  const [showGrowthError, setShowGrowthError] = useState("");
  const [backArrowLink, setBackArrowLink] = useState("");
  const [viewEucostCagrValue, setViewEucostCagrValue] = useState(null);
  const [imoMtmEntries, setImoMtmEntries] = useState([]);




  const [showSpin, setShowSpin] = useState(false);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };

  const tabs = [
    {
      id: "imo",
      label: "IMO",
      content: (
        <ImoTabContent
          zFactorValue={zFactorValue}
          setZFactorValue={setZFactorValue}
          imoLevyAmount={imoLevyAmount}
          setImoLevyAmount={setImoLevyAmount}
          imoPenalty={imoPenalty}
          setImoPenalty={setImoPenalty}
          startYear={startYear}
          setStartYear={setStartYear}
          showYearError={showYearError}
          setShowYearError={setShowYearError}
          years={years}
          setError={setError}
          setErrorPenalty={setErrorPenalty}
          error={error}
          errorPenalty={errorPenalty}
          errorLevyAmount={errorLevyAmount}
          setErrorLevyAmount={setErrorLevyAmount}
          imoMtmEntries={imoMtmEntries} //  Pass imoMtmEntries here
        />
      ),
    },
    {
      id: "eu",
      label: "EU",
      content: (
        <EuTabContent
          euPenalty={euPenalty}
          setEuPenalty={setEuPenalty}
          euCostEur={euCostEur}
          setEuCostEur={setEuCostEur}
          euCostCagr={euCostCagr}
          setEuCostCagr={setEuCostCagr}
          viewEucostCagrValue={viewEucostCagrValue}
          setViewEucostCagrValue={setViewEucostCagrValue}
          baseYear={baseYear}
          setBaseYear={setBaseYear}
          showBaseYearError={showBaseYearError}
          setShowbaseYearError={setShowBaseYearError}
          years={years}
          euPenaltyError={euPenaltyError}
          setEuPenaltyError={setEuPenaltyError}
          euCostError={euCostError}
          setEuCostError={setEuCostError}
          setShowGrowthError={setShowGrowthError}
          showGrowthError={showGrowthError}
        />
      ),
    },
  ];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const handleBackUrl = () => {
    const url = localStorage.getItem("appUrl")
    setBackArrowLink(url);
  }
  const showToast = (message, toastId) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
      toastId: toastId,
    });
  };


  const validateLegislativeScenario = () => {
    if (legislativeScenario.id === null) {
      return { isValid: false, message: "Please select legislative scenario", tab: "general" };
    }
    return { isValid: true };
  };

  const validateLevyAmount = () => {
    const parsedValue = parseFloat(imoLevyAmount);
    if (isNaN(parsedValue) || parsedValue < 0 || parsedValue > 500 || imoLevyAmount?.startsWith("-")) {
      return { isValid: false, message: "Please enter a levy amount between 0 and 500", tab: "imo" };
    }
    return { isValid: true };
  };

  const validateStartYear = () => {
    if (startYear.id === null) {
      return { isValid: false, message: "Please select start year", tab: "imo" };
    }
    return { isValid: true };
  };

  const validateZFactor = () => {
    if (zFactorValue < 0 || zFactorValue > 0.1 || error.length > 0 || zFactorValue === null || isNaN(zFactorValue)) {
      return { isValid: false, message: "Z-factor must be between 0% and 10%", tab: "imo" };
    }
    return { isValid: true };
  };

  const validateImoPenalty = () => {
    const parsedImoPenalty = parseFloat(imoPenalty);
    if (parsedImoPenalty < 0 || parsedImoPenalty > 5000 || isNaN(parsedImoPenalty) || imoPenalty.startsWith("-")) {
      return { isValid: false, message: "Please enter penalty between 0 to 5000", tab: "imo" };
    }
    return { isValid: true };
  };

  const validateEuCostEur = () => {
    const parsedEuCostEur = parseFloat(euCostEur);
    if (parsedEuCostEur < 0 || parsedEuCostEur > 500 || isNaN(parsedEuCostEur) || euCostEur.startsWith("-")) {
      return { isValid: false, message: "Cost of allowance must be 0 to 500", tab: "eu" };
    }
    return { isValid: true };
  };

  const validateBaseYear = () => {
    if (baseYear.id === null) {
      return { isValid: false, message: "Please select base year", tab: "eu" };
    }
    return { isValid: true };
  };

  const validateEuPenalty = () => {
    const parsedEuPenalty = parseFloat(euPenalty);
    if (parsedEuPenalty < 0 || parsedEuPenalty > 5000 || isNaN(parsedEuPenalty) || euPenalty.startsWith("-")) {
      return { isValid: false, message: "Please enter penalty between 0 to 5000", tab: "eu" };
    }
    return { isValid: true };
  };

  const validateEuCostCagr = () => {
    if (euCostCagr < 0 || euCostCagr > 0.1 || isNaN(euCostCagr) || showGrowthError.length > 0) {
      return { isValid: false, message: "Growth p/a must be 0 to 10%", tab: "eu" };
    }
    return { isValid: true };
  };

  const handleUpdateScenario = () => {
    const validations = [
      validateLegislativeScenario(),
      validateLevyAmount(),
      validateStartYear(),
      validateZFactor(),
      validateImoPenalty(),
      validateEuCostEur(),
      validateBaseYear(),
      validateEuPenalty(),
      validateEuCostCagr(),
    ];

    for (let validation of validations) {
      if (!validation.isValid) {
        showToast(`${validation.tab.toUpperCase()}: ${validation.message}`, `${validation.tab}_validation_error`);
        return;
      }
    }

    setShowSpin(true);
    const transformedData = {
      id: legislativeScenario.id,
      scenarioName: legislativeScenario.item,
      imo_cii_z_from_2027: zFactorValue,
      imo_ghg_levy_amout_usd_co2eq: parseFloat(imoLevyAmount),
      imo_ghg_levy_start_year: startYear.item,
      imo_Intensity_penalty_eur_gj: parseFloat(imoPenalty),
      eufmPenalty_eur_gj: parseFloat(euPenalty),
      euets_allowance_cost_eur_co2eq: parseFloat(euCostEur),
      euets_allowance_base_year: euBaseYear,
      euets_allowance_cagr: euCostCagr,
    };

    updateScenario(transformedData)
      .then((res) => {
        console.log(res.data);
        toast.success("Legislative scenario has been updated.", {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          toastId: "update_price_template",
        });
        setShowSpin(false);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("error update scenario", err);
      });
  };


  const getAllLegislativeScenario = () => {
    allLegislativeScenario()
      .then((res) => {
        const scenario = res.data.map((s, index) => ({
          id: s?.id,
          item: s?.scenarioName,
        }));
        setLegislativeScenarios(scenario);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("error in price forcast scenario", err);
      });
  };

  const getScenarioById = (item) => {
    setLoading(true);
    getOneScenario(item)
      .then((res) => {
        const data = res.data;
        setSelectedScenarioData(data);

        const foundYear = years.find(
          (obj) => obj.item === data?.imo_ghg_levy_start_year
        );
        setStartYear(foundYear);

        const findBaseYear = years.find(
          (obj) => obj.item === data?.euets_allowance_base_year
        );
        setBaseYear(findBaseYear);

        setImoLevyAmount(`${data?.imo_ghg_levy_amout_usd_co2eq?.toFixed(1)}`);
        setImoPenalty(`${data?.imo_Intensity_penalty_eur_gj?.toFixed(1)}`);
        setZFactorValue(data?.imo_cii_z_from_2027);
        setEuBaseYear(data?.euets_allowance_base_year);
        setEuCostEur(`${data?.euets_allowance_cost_eur_co2eq?.toFixed(1)}`);
        setEuCostCagr(data?.euets_allowance_cagr);
        setEuPenalty(`${data?.eufmPenalty_eur_gj?.toFixed(1)}`);
        setViewEucostCagrValue(data?.euets_allowance_cagr * 100);

        // Process imo_mtm_entry
        const sortedMtmEntries = (data?.imo_mtm_entry || []).sort((a, b) => a.year_ - b.year_);
        setImoMtmEntries(sortedMtmEntries); // Assuming a state setter exists

        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("get one scenario err", err);
      });
  };

  const getOwnerPermission = () => {
    getOwner()
      .then((result) => {
        setOwnerInfo(result.data);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("err get company name", err);
      });
  };

  useEffect(() => {
    getAllLegislativeScenario();
    getOwnerPermission();
  }, []);

  useEffect(() => {
    if (legislativeScenario.id !== null) {
      getScenarioById(legislativeScenario.id);
      getAllLegislativeScenario();
    }
  }, [legislativeScenario, setLegislativeScenarios]);

  const handleLegislativeScenarioChange = (item) => {
    if (item.id !== null) {
      getScenarioById(item.id);
    }
    dispatch(setLegislativeScenarioData(item));
  };

  useEffect(() => {
    if (legislativeScenarios.length > 0 && legislativeScenario.id === null) {
      dispatch(setLegislativeScenarioData(legislativeScenarios[0]));
    }
  }, [legislativeScenarios, legislativeScenario]);

  return (
    <>
      <div className="login-container h-100 splash-screen pb-5 ">
        <NavbarComponent showfaq={false} show={true} />
        <main className="container-padding pt-4">
          <div className="legislativ-scenario-container ">
            <div className="title">
              <a href={backArrowLink}>
                <button
                  className="btn btn-primary add"
                  onClick={() => handleBackUrl()}>
                  <img src={BackArrow} alt="" />
                </button>
              </a>
              <h1 className="mb-0 fs-28 text-dark d-flex">
                {ownerInfo?.owner?.name} Legislative Scenarios
                <span className="beta h-100 bg-primary ms-3">BETA</span>
              </h1>
              <div className="select-scenario d-flex ms-auto align-items-center">
                <p className="input-label mb-0 me-2">Scenario</p>

                <SelectBox
                  selectedItem={legislativeScenario}
                  listItems={legislativeScenarios}
                  setSelectedItem={handleLegislativeScenarioChange}
                  disabledOptions={[legislativeScenario]}
                  showError={showLegislativeError}
                  setShowError={setShowLegislativeError}
                  showAddElement={true}
                  addElementOptionName="Add New Scenario Name"
                  className="select-box-v2"
                />
              </div>
            </div>
          </div>
          <div className="legislative-main-container mt-4">
            <ul className="nav nav-tabs pb-4" id="myTab" role="tablist">
              {tabs.map((tab) => (
                <li className="nav-item" role="presentation" key={tab.id}>
                  <button
                    className={`nav-link ${tab.id === "imo" ? "active" : ""}`}
                    id={`${tab.id}-tab`}
                    data-bs-toggle="tab"
                    data-bs-target={`#${tab.id}-tab-pane`}
                    type="button"
                    role="tab"
                    aria-controls={`${tab.id}-tab-pane`}
                    aria-selected={tab.id === "imo"}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab.label}
                  </button>
                </li>
              ))}
            </ul>
            <div
              className="tab-content rounded-16 p-4 shadow"
              id="myTabContent"
            >
              {tabs.map((tab) => (
                <div
                  className={`tab-pane fade ${tab.id === "imo" ? "show active" : ""
                    }`}
                  id={`${tab.id}-tab-pane`}
                  role="tabpanel"
                  aria-labelledby={`${tab.id}-tab`}
                  tabIndex="0"
                  key={tab.id}
                >
                  {loading ? (
                    <div className="d-flex align-items-center justify-content-center h-100">
                      <Loading loading={loading} />
                    </div>
                  ) : (
                    tab.content
                  )}
                </div>
              ))}
              <div className="actions">
                {/* <button
                  className="btn btn-secondary btn-lg fs-16"
                  onClick={() => navigate("/")}
                >
                  Cancel
                </button> */}
                <button
                  className="btn btn-primary btn-lg fs-16"
                  onClick={handleUpdateScenario}
                >
                  Save
                  {showSpin && (
                    <div className="spinner-border" role="status"></div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
      {/* <ErrorOverlaymodal
        show={open}
        handleClose={handleClose}
        errorMessage={
          error
            ? error.response
              ? error.response.data.message
              : "Unknown error occurred"
            : "No error"
        }
      /> */}
    </>
  );
};

export default LegislativeScenario;
