import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import dragdataPlugin from "../../utils/chartjs-plugin-dragdata";
import { Chart, registerables } from "chart.js";
import Loading from "../Loading";
import { useSelector, useDispatch } from "react-redux";
import {
  addRefineData,
  clearRefineData,
} from "../../redux/reducers/refineDataSlice";
import { refinePrices, graphPrices } from "../../api/data";
import { toast } from "react-toastify";
import CustomTooltip from "../CustomTooltip";
Chart.register(...registerables, dragdataPlugin);

const adjustColor = (hex, offset) => {
  let num = parseInt(hex.slice(1), 16);
  let r = (num >> 16) + offset;
  let g = ((num >> 8) & 0x00ff) + offset;
  let b = (num & 0x0000ff) + offset;

  r = Math.min(255, Math.max(0, r));
  g = Math.min(255, Math.max(0, g));
  b = Math.min(255, Math.max(0, b));

  return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`;
};

const RefineChart = ({
  checkedPrice,
  chartDatas,
  fuelFamily,
  fuelOrigin,
  fuelTypeName,
  selectedBasin,
  loading,
  allFuelData,
}) => {
  const priceScenario = useSelector(
    (state) => state.selectedPriceScenario.priceScenario
  );
  const dispatch = useDispatch();

  const refineArrayData = useSelector(
    (state) => state.refineDataSlice.refineData
  );

  const [label, setLabel] = useState([]);
  const [data, setData] = useState([]);
  const [title, setTitle] = useState("Price by MMBtu (LHV)");
  const [isEnabled, setIsEnabled] = useState(false);

  const permissions = useSelector((state) => state.permissions.permissions);
  const canAccessRoute = (requiredPermission) => {
    return permissions.some(
      (permission) =>
        permission.licensePermission.permissionName === requiredPermission
    );
  };

  useEffect(() => {
    if (checkedPrice === true) {
      setTitle("Price by MMBtu (LHV)");
    } else {
      setTitle("Price by Mt");
    }
  }, [checkedPrice]);

  useEffect(() => {
    if (!chartDatas || Object.keys(chartDatas).length === 0) {
      setLabel([]);
      setData([]);
      return;
    }

    let getSelectedFuelArray = [];
    allFuelData.forEach((fuel) => {
      fuelOrigin.forEach((origin) => {
        if (fuel?.family?.fuelFamilyName === fuelFamily?.item && fuel?.origin?.id === origin.id) {
          getSelectedFuelArray.push(fuel);
        }
      });
    });

    // Extract all available years
    const getUniqueYears = (fuels) => {
      const yearsSet = new Set();

      for (const fuelType in fuels) {
        const fuelData = fuels[fuelType];
        for (const entry of fuelData) {
          yearsSet.add(entry.year);
        }
      }
      return [...yearsSet].sort((a, b) => a - b);
    };

    const fuelValues = Object.values(chartDatas)[0];
    const uniqueYears = getUniqueYears(fuelValues);
    setLabel(uniqueYears);

    const fuelDatasetsNormalized = getSelectedFuelArray
      .filter(fuel => (chartDatas?.[Object.keys(chartDatas)[0]]?.[fuel.id] || []).length > 0) // Filter out fuels with no data
      .map((fuel) => {
        const fuelProductId = fuel.id;
        const fuelData = fuelValues[fuelProductId] || [];

        // Convert array to a map for quick lookup
        const fuelDataMap = new Map(fuelData.map((entry) => [entry.year, entry]));

        // Precompute idMod to avoid redundant calculations
        const idMod = fuel?.id % 10;

        const colorOffset = ((fuel?.origin?.colorOffset || 50) - 50) * 1.6; // Scale to range -80 to +80
        const adjustedColor = adjustColor(fuel?.family?.colorHex, colorOffset);


        // Fill missing years with `null`
        const normalizedData = uniqueYears.map((year) => {
          return fuelDataMap.has(year)
            ? checkedPrice
              ? +fuelDataMap.get(year).price_LHV.toFixed(1)
              : +fuelDataMap.get(year).price_mt.toFixed(1)
            : null;
        });

        return {
          label: fuel.fuelTypeName,
          data: normalizedData,
          data_mt: uniqueYears.map((year) => fuelDataMap.get(year)?.price_mt?.toFixed(2) || null),
          data_lhv: uniqueYears.map((year) => fuelDataMap.get(year)?.price_LHV?.toFixed(2) || null),
          data_w2t_ci: uniqueYears.map((year) => fuelDataMap.get(year)?.wellToTankCI?.toFixed(4) || null),
          data_w2t_ci_MJ: uniqueYears.map((year) => fuelDataMap.get(year)?.wellToTankCI_MJ?.toFixed(4) || null),
          fuelProductId: fuelProductId,
          borderDash:
            // line length,  gap
            fuel?.origin?.pattern === "dash"
              ? [4 * idMod, 4 * idMod] // Increase dash size
              : fuel?.origin?.pattern === "dot"
                ? [3 * idMod, 3 * idMod] // Adjust dot pattern
                : [0, 0],
          backgroundColor: adjustedColor,
          borderColor: adjustedColor,
          fill: false,
          lineTension: 0.4,
          radius: 1,
          borderWidth: 2,
          pointHitRadius: 25,
          pointRadius: 3,
        };
      });

    setData(fuelDatasetsNormalized);
  }, [chartDatas, fuelOrigin, fuelFamily, fuelTypeName, allFuelData]);

  const chartData = {
    labels: label,
    datasets: data,
  };

  const options = {
    maintainAspectRatio: false,
    hover: {
      mode: 'nearest', // Ensure hover is triggering on nearest point
      intersect: false, // Tooltips can show even when hovering between points
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
        ticks: {
          color: "#3c4450",
          font: "10px",
          lineHeight: "13px",
          callback: function (value) {
            return "$" + value;
          },
        },
        title: {
          display: true,
          text: `${checkedPrice ? "Fuel Price [USD/MMBtu]" : "Fuel Price [USD/Mt]"
            }`,
          color: "#9E9E9E",
          lineHeight: "13px",
          padding: { bottom: 17 },
        },
      },
      x: {
        grid: {
          display: true,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const dataset = context.dataset;
            const dataIndex = context.dataIndex;

            // Only show tooltip when we have data available
            if (dataset.data[dataIndex] !== null && dataset.data[dataIndex] !== undefined) {
              const price_mt = dataset.data_mt[dataIndex];
              const price_lhv = dataset.data_lhv[dataIndex];
              const w2t_ci = dataset.data_w2t_ci[dataIndex];
              const w2t_ci_MJ = dataset.data_w2t_ci_MJ[dataIndex];
              return [
                `${dataset.label}`,
                `Fuel Price:`,
                `\t\t\t\t\t$${(price_mt && !isNaN(parseFloat(price_mt)))
                  ? parseFloat(price_mt).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                  : 'N/A'                 } /mt`,
                `\t\t\t\t\t$${isNaN(parseFloat(price_lhv)) ? 'N/A' : parseFloat(price_lhv).toFixed(2)} /MMBtu`,
                `Well-to-Tank CI:`,
                `\t\t\t\t\t${isNaN(parseFloat(w2t_ci)) ? 'N/A' : parseFloat(w2t_ci).toFixed(4)} mt CO2eq/mt fuel`,
                `\t\t\t\t\t${isNaN(parseFloat(w2t_ci_MJ)) ? 'N/A' : parseFloat(w2t_ci_MJ).toFixed(4)} g CO2eq/MJ`
              ];
            }
            return "No Data Available";
          },
        },
      },
      legend: {
        position: "bottom",
        title: {
          text: `${checkedPrice ? "Price by MMBtu (LHV)" : "Price by Mt"}`,
        },
        labels: {
          padding: 20,
          color: "#000",
          font: {
            size: 11,
            lineHeight: 14,
          },
          boxWidth: 70,
          boxHeight: 0,
          generateLabels: (chart) => {
            return chart.data.datasets.map((dataset) => ({
              text: dataset.label,
              strokeStyle: dataset.borderColor,
              lineDash: dataset.borderDash || [], // Ensures legend matches chart styles
              lineDashOffset: 5,
              lineWidth: 4,
            }));
          },
        },
      },
      datalabels: {
        display: false,
      }
    },
  };

  if (canAccessRoute("EditPriceForecasts")) {
    options.plugins.dragData = {
      round: 1,
      showTooltip: true,
      onDragStart: (e) => {
        if (!canAccessRoute("EditPriceForecasts")) {
          e.preventDefault();
          return;
        }
      },
      onDragEnd: async (event, datasetIndex, dataIndex, value) => {
        if (!canAccessRoute("EditPriceForecasts")) {
          event.preventDefault();
          return;
        }

        const draggedDataset = data[datasetIndex];
        const priceYr = label[dataIndex];
        let unit = checkedPrice ? "mmbtu" : "mt";

        let addDragData = {
          myBasin: selectedBasin.id,
          scenario: priceScenario.id,
          fuelProductId: draggedDataset.fuelProductId,
          priceYear: priceYr,
          price: value,
        };

        draggedDataset.data[dataIndex] = value;

        if (checkedPrice) {
          draggedDataset.data_lhv[dataIndex] = value;
        } else {
          draggedDataset.data_mt[dataIndex] = value;
        }

        dispatch(addRefineData(addDragData));

        try {
          const response = await graphPrices(unit, addDragData);
          const updatedPrices = response.data;

          draggedDataset.data_mt[dataIndex] = updatedPrices.price_mt;
          draggedDataset.data_lhv[dataIndex] = updatedPrices.price_LHV;

          setData(prevData => {
            const newData = [...prevData];
            newData[datasetIndex].data[dataIndex] = draggedDataset.data[dataIndex];
            newData[datasetIndex].data_mt[dataIndex] = draggedDataset.data_mt[dataIndex];
            newData[datasetIndex].data_lhv[dataIndex] = draggedDataset.data_lhv[dataIndex];
            return newData;
          });
        } catch (err) {
          console.log("Failed to fetch updated prices", err);
        }
      }

    }
  }

  const addRefinePrice = (unit, refinePriceData) => {
    refinePrices(unit, refinePriceData)
      .then(() => {
        toast.success("Your changes have been saved", {
          position: "top-right",
          autoClose: 8000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          toastId: "updated_refine_data",
        });
      })
      .catch((err) => console.log("refine price err", err));
  };

  const handleRefineData = () => {
    const priceUnit = checkedPrice ? "mmbtu" : "mt";
    addRefinePrice(priceUnit, refineArrayData);

    dispatch(clearRefineData());
  }

  const enableConfirment = () => {
    return (
      priceScenario.item === "Select" ||
      fuelFamily.item === "Select" ||
      selectedBasin.item === "Select" ||
      !priceScenario.item ||
      !fuelFamily.item ||
      !selectedBasin.item ||
      refineArrayData.length === 0
    );
  };

  useEffect(() => {
    const isDisabled = enableConfirment();
    setIsEnabled(isDisabled);
  }, [priceScenario, fuelFamily, selectedBasin]);


  return (
    <>
      <div className="refine-price-chart-container">
        <p className="mb-0 d-none">{title}</p>
        <div className="chart-container border-1 rounded-3 mt-2">
          {label.length === 0 ||
            data.length === 0 ||
            (loading && (
              <div className="d-flex align-items-center justify-content-center h-100">
                <Loading
                  loading={label.length === 0 || data.length === 0 || loading}
                  height={30}
                />
              </div>
            ))}
          {label.length > 0 && data.length > 0 && !loading && (
            <Line options={options} data={chartData} />
          )}
        </div>
      </div>
      <div className="actions mt-3 d-flex justify-content-end w-100 gap-3">
        {(canAccessRoute("EditPriceForecasts") || canAccessRoute("BatchPriceEditor")) && (
          <div>
            {isEnabled && refineArrayData.length === 0 ? (
              <CustomTooltip
                tooltipText={"This control is enabled only after changes are made in the graph. Drag and drop each price point and then confirm your changes."}
                placement="top"
              >
                <button className="btn btn-primary btn-lg fs-16" disabled={enableConfirment()} onClick={() => handleRefineData()}>
                  Confirm Refinement
                </button>
              </CustomTooltip>
            ) : (
              <button className="btn btn-primary btn-lg fs-16" disabled={enableConfirment()} onClick={() => handleRefineData()}>
                Confirm Refinement
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default RefineChart;
