import React, { useState, useEffect } from "react";
import closeIcon from "../assets/closeIcon3.svg";
import crossIcon from "../assets/crossIcon.svg";
import Modal from "react-bootstrap/Modal";
import { reactAppConfig } from "../config/reactAppConfig";


const ErrorOverlayModal = ({
  show,
  handleClose,
  errorMessage,
}) => {
  const [truncatedErrorMessage, setTruncatedErrorMessage] = useState("");

  useEffect(() => {
    if (errorMessage && typeof errorMessage === "string") {
      if (errorMessage.length > 300) {
        setTruncatedErrorMessage(`${errorMessage.slice(0, 300)}...`);
      } else {
        setTruncatedErrorMessage(errorMessage);
      }
    }
  }, [errorMessage]);

  const [contactUsLink, setContactUsLink] = useState("");

  const redirectToHome = () => {
    const url = localStorage.getItem("appUrl");
    window.location.href = url;
  };

  const handleModalClose = (event, reason) => {
    if (reason !== "escapeKeyDown") {
      handleClose(event, reason);
    }
  };
  const handleContactUs = () => {
    const subject = "Error encountered during Pool operation";
    const body = `
      The following error was encountered during operations:
      
      ${errorMessage}
    `;

    const mailtoLink = `mailto:contact@z-joule.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    setContactUsLink(mailtoLink);
  };

  return (
    <Modal
      show={show}
      onClose={handleModalClose}
      aria-labelledby="error-modal-title"
      aria-describedby="error-modal-description"
      centered
      size="md"
    >
      <Modal.Body>
        <div
          className="error-box"
          sx={{
            bgcolor: "background.paper",
            boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.12)",
            borderRadius: "15px",
            position: "relative",
          }}
        >
          <div
            className="close-popup"
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
            onClick={redirectToHome}
          >
            <img src={closeIcon} alt="Close Icon" />
          </div>

          <div className="cross-icon">
            <div className="cross-icon-svg">
              <img src={crossIcon} alt="Cross Icon" />
            </div>

            <div>
              <span
                id="error-modal-title"
                style={{
                  color: "#717171",
                  fontSize: "28px",
                  fontWeight: 600,
                  lineHeight: "1.5",
                  leadingTrim: "both",

                  textEdge: "cap",
                  fontStyle: "normal",
                }}
              >
                ERROR
              </span>
            </div>
          </div>
        </div>
        <div style={{ textAlign: "center", margin: "2rem" }}>
          <span
            id="error-modal-description"
            style={{
              alignSelf: "stretch",
              color: "#000",
              textAlign: "center",
              leadingTrim: "both",

              textEdge: "cap",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "19.2px",
              wordWrap: "break-word",
            }}
          >
            {truncatedErrorMessage}
          </span>
        </div>

        <div className="d-flex align-items-center justify-content-center text-center">
          <a href={contactUsLink}>
            <button
              className="btn btn-primary btn-lg rounded"
              type="button"
              onClick={handleContactUs}
            >
              Contact Us
            </button>
          </a>
        </div>


      </Modal.Body>
    </Modal>
  );
};

export default ErrorOverlayModal;
