import NavbarComponent from "../components/Navbar";
import PowerCurveChart from "../components/charts/PowerCurveChart";
import { reactAppConfig } from "../config/reactAppConfig";
import { useEffect, useState } from "react";
import {
  allVesselDto,
  allVesselFamilies,
  allVessels,
  allVesselType,
  generatePowerGraph,
  getOwner,
  updateVesselType,
  vesselById,
  vesselTypeById,
} from "../api/data";
import SelectBox from "../components/SelectBox";
import BackArrow from "../assets/arrow-left.svg";
import { CommonOverlayModal } from "../components/CommonOverlayModal";
import { setSelectedVesselData } from "../redux/reducers/selectedVesselReducer";
import { useDispatch, useSelector } from "react-redux";
import useNumericInput from "../hooks/useNumericInput";
import { toast } from "react-toastify";
import { capitalizeAllLetters } from "../utils/formatter";

const VesselTypeEditor = () => {
  const dispatch = useDispatch();
  const [backArrowLink, setBackArrowLink] = useState("");
  const [ownerInfo, setOwnerInfo] = useState(null);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [vesselList, setVesselList] = useState([]);
  const [vesselInfo, setVesselInfo] = useState(null);
  const [vesselFamilyList, setVesselFamilyList] = useState([]);
  const [selectedVesselFamily, setSelectedVesselFamily] = useState({
    id: null,
    item: "Select",
  });
  const [capacityUnit, setCapacityUnit] = useState(null);
  const [vesselTypeName, setVesselTypeName] = useState(null);
  const [capacityValue, setCapacityValue] = useState(null);
  const [minSpeed, setMinSpeed] = useState(null);
  const [maxSpeed, setMaxSpeed] = useState(null);
  const [notes, setNotes] = useState(null);
  const [showNotes, setShowNotes] = useState(null);
  const [meValue, setMEValue] = useState(null);
  const [auxValue, setAuxValue] = useState(null);
  const [boilerValue, setBoilerValue] = useState(null);
  const [powerChartData, setPowerChartData] = useState([]);
  const { handleOnKeyDown } = useNumericInput();
  const selectedVessel = useSelector(
    (state) => state.selectedVesselItem.selectedVessel
  );
  const [showMinSpeedError, setMinSpeedError] = useState(null);
  const [showMaxSpeedError, setMaxSpeedError] = useState(null);
  const [vesselNameError, setVesselNameError] = useState(null);
  const [powerCurveBase, setPowerCurveBase] = useState(null);
  const [powerExponent, setPowerExponent] = useState(null);
  const [powerBaseError, setPowerBaseError] = useState(null);
  const [powerExpError, setPowerExpError] = useState(null);
  const handleBackUrl = () => {
    const url = localStorage.getItem("appUrl");
    setBackArrowLink(url);
  };

  const showToast = (message, toastId) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
      toastId: toastId,
    });
  };

  const getOwnerPermission = () => {
    getOwner()
      .then((result) => {
        setOwnerInfo(result.data);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("err get company name", err);
      });
  };

  const getAllVesselTypes = () => {
    allVesselType()
      .then((result) => {
        const vessel = result.data.map((v, index) => ({
          id: v?.id,
          item: v?.vesselTypeName,
        }));
        setVesselList(vessel);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("err get company name", err);
      });
  };

  const getAllVesselFamilies = () => {
    allVesselFamilies().then((result) => {
      const vesselFamily = result.data.map((vf, index) => ({
        id: vf?.id,
        item: vf?.vesselFamilyName,
      }));
      setVesselFamilyList(vesselFamily);
    });
  };

  const getVesselTypeForId = (vesselId) => {
    const id = parseInt(vesselId);
    vesselTypeById(id)
      .then((result) => {
        const vesselData = result?.data;
        setVesselInfo(vesselData);
        setVesselTypeName(vesselData?.vesselTypeName);
        setCapacityValue(vesselData?.capacity.toFixed(1));
        setMinSpeed(vesselData?.speedMin);
        setMaxSpeed(vesselData?.speedMax);
        setMEValue(vesselData?.installedMEpower);
        setAuxValue(vesselData?.installedAUXpower);
        setBoilerValue(vesselData?.installedBoilerPower);
        setPowerChartData(vesselData?.speedGraph);
        setPowerCurveBase(vesselData?.powerCurveBase.toFixed(1));
        setPowerExponent(vesselData?.powerCurveExp.toFixed(1));
        setCapacityUnit(vesselData?.family?.vesselUnits?.suname);
        setShowNotes(vesselData?.notesHTML);
        const vesselFamily = vesselData?.family;
        if (vesselFamily) {
          setSelectedVesselFamily({
            id: vesselFamily.id,
            item: vesselFamily.vesselFamilyName,
          });
        } else {
          setSelectedVesselFamily({ id: null, item: "Select" });
        }
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("err get company name", err);
      });
  };

  const resetErrorMessages = () => {
    setMinSpeedError(null);
    setMaxSpeedError(null);
    setVesselNameError(null);
    setPowerCurveBase(null);
    setPowerExponent(null);
    setPowerBaseError(null);
    setPowerExpError(null);
  };

  const handleSelectedVesselChange = (item) => {
    resetErrorMessages();

    if (item?.id) {
      getVesselTypeForId(item.id);
    }
    dispatch(setSelectedVesselData(item));
  };

  const handleChangeVesselFamily = (item) => {
    setSelectedVesselFamily(item);
  };

  const handleChangeVesselTypeName = (e) => {
    let name = e.target.value;

    if (name.trim() === "") {
      setVesselNameError("Vessel Type Name cannot be empty");
      setVesselTypeName("");
    } else if (name.length > 20) {
      name = name.slice(0, 20);
      setVesselNameError("Name must be less than 20 characters");
      setVesselTypeName(name);
    } else {
      setVesselNameError("");
      setVesselTypeName(name);
    }
  };

  const handleChangeMinSpeed = (e) => {
    let value = e.target.value;
    const parsedValue = parseFloat(value);
    const parsedMaxSpeed = parseFloat(maxSpeed);
    setMinSpeed(value);
    validateSpeed(parsedValue, parsedMaxSpeed);
  };

  const handleChangeMaxSpeed = (e) => {
    let value = e.target.value;
    const parsedValue = parseFloat(value);
    const parsedMinSpeed = parseFloat(minSpeed);
    setMaxSpeed(value);
    validateSpeed(parsedMinSpeed, parsedValue);
  };

  const validateSpeed = (parsedMinSpeed, parsedMaxSpeed) => {
    const validateDecimal = (value) => value % 1 === 0 || value % 1 === 0.5;

    if (isNaN(parsedMinSpeed) || parsedMinSpeed < 1 || parsedMinSpeed > 15) {
      setMinSpeedError("Min speed must be between 1 and 15");
    } else if (parsedMinSpeed > parsedMaxSpeed) {
      setMinSpeedError("Min speed must be less than max speed");
    } else if (!validateDecimal(parsedMinSpeed)) {
      setMinSpeedError("Only .0 or .5 is allowed after decimal");
    } else {
      setMinSpeedError("");
    }

    if (isNaN(parsedMaxSpeed) || parsedMaxSpeed < 10 || parsedMaxSpeed > 20) {
      setMaxSpeedError("Max speed must be between 10 and 20");
    } else if (parsedMaxSpeed < parsedMinSpeed) {
      setMaxSpeedError("Max speed must be greater than min speed");
    } else if (!validateDecimal(parsedMaxSpeed)) {
      setMaxSpeedError("Only .0 or .5 is allowed after decimal");
    } else {
      setMaxSpeedError("");
    }
  };

  const handleChangeNotes = (e) => {
    let input = e.target.value;

    if (input.length > 500) {
      input = input.substring(0, 500);
    }
    const formattedNotes = `<p>${input}</p>`;

    setShowNotes(input);
    setNotes(formattedNotes);
  };

  const handleChangePowerBase = (e) => {
    let value = e.target.value;

    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue) || parsedValue < 0 || parsedValue > 10) {
      value = value.slice(0, 2);
      setPowerCurveBase(value);
      setPowerBaseError("Value must be between 0 and 10");
    } else {
      setPowerCurveBase(value);
      setPowerBaseError("");
    }
  };

  const handleChangePowerExponent = (e) => {
    let value = e.target.value;

    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue) || parsedValue < 0 || parsedValue > 10) {
      value = value.slice(0, 2);
      setPowerExponent(value);
      setPowerExpError("Value must be between 0 and 10");
    } else {
      setPowerExponent(value);
      setPowerExpError("");
    }
  };

  const handleChangeCapacity = (e) => {
    setCapacityValue(e.target.value);
  };

  const handleInputChange = (e, type) => {
    const value = e.target.value;

    const numericValue = value === "" ? "" : parseFloat(value);

    if (isNaN(numericValue) && value !== "") {
      showToast("Please enter a valid number.", type);
      return;
    }

    switch (type) {
      case "ME":
        if (numericValue < 1 || numericValue > 200000) {
          showToast("ME power be between 1 and 200000 KW", "ME");
        }
        setMEValue(value);
        break;

      case "AUX":
        if (numericValue < 1 || numericValue > 20000) {
          showToast("AUX power must be between 1 and 20000 KW", "AUX");
        }
        setAuxValue(value);
        break;

      case "Boiler":
        if (numericValue < 0 || numericValue > 20000) {
          showToast("Boiler power be between 0 and 20000 KW", "Boiler");
        }
        setBoilerValue(value);
        break;

      default:
        break;
    }
  };

  const handleSave = () => {
    const id = selectedVessel?.id;
    const data = {
      vesselTypeName: vesselTypeName,
      vesselFamily: selectedVesselFamily?.id,
      capacity: capacityValue,
      speedMax: maxSpeed,
      speedMin: minSpeed,
      powerCurveBase: powerCurveBase,
      powerCurveExp: powerExponent,
      installedBoilerPower: boilerValue,
      installedAUXpower: auxValue,
      installedMEpower: meValue,
      notesHTML: notes,
    };

    const fieldsToValidate = [
      { field: vesselTypeName, label: "Vessel Type Name" },
      { field: selectedVesselFamily?.id, label: "Vessel Family" },
      { field: capacityValue, label: "Capacity" },
      { field: maxSpeed, label: "Max Speed" },
      { field: minSpeed, label: "Min Speed" },
      { field: boilerValue, label: "Installed Boiler Power" },
      { field: auxValue, label: "Installed AUX Power" },
      { field: meValue, label: "Installed ME Power" },
      // { field: notes, label: "Notes" }
    ];

    const missingFields = fieldsToValidate
      .filter((item) => {
        if (typeof item.field === "number") {
          return isNaN(item.field);
        }
        return item.field === null || item.field === "";
      })
      .map((item) => item.label);

    if (missingFields.length > 0) {
      toast.error(
        `Following fields cannot be empty: ${missingFields.join(", ")}`
      );
      return;
    }

    updateVesselType(id, data)
      .then((response) => {
        toast.success("Data updated successfully");
      })
      .catch((error) => {
        toast.error("Error updating data");
      });
  };

  const getSpeedPowerGraph = (vesselTypeId) => {
    const data = {
      speed1: minSpeed,
      speed2: maxSpeed,
      powerCurveBase: powerCurveBase,
      powerCurveExponent: powerExponent,
    };

    if (
      (showMaxSpeedError !== "" && showMaxSpeedError !== null) ||
      (showMinSpeedError !== "" && showMinSpeedError !== null) ||
      (powerBaseError !== "" && powerBaseError !== null) ||
      (powerExpError !== "" && powerExpError !== null)
    ) {
      return;
    } else {
      generatePowerGraph(vesselTypeId, data)
        .then((res) => {
          setPowerChartData(res.data);
        })
        .catch((error) => {
          toast.error("Error fetching data", "graph-toast");
        });
    }
  };

  const isSaveButtonDisabled = () => {
    if (
      (showMinSpeedError !== null && showMinSpeedError !== "") ||
      (showMaxSpeedError !== null && showMaxSpeedError !== "") ||
      (vesselNameError !== null && vesselNameError !== "") ||
      (powerBaseError !== null && powerBaseError !== "") ||
      (powerExpError !== null && powerExpError !== "")
    ) {
      return true;
    } else {
      return false;
    }
  };

  function stripHtml(html) {
    return html?.replace(/<\/?[^>]+(>|$)/g, "");
  }

  useEffect(() => {
    getOwnerPermission();
    getAllVesselTypes();
    getAllVesselFamilies();
  }, []);

  useEffect(() => {
    if (vesselList.length > 0 && selectedVessel.id === null) {
      const firstVessel = vesselList[0];
      dispatch(setSelectedVesselData(firstVessel));
      getVesselTypeForId(firstVessel.id);
    }
  }, [vesselList, selectedVessel]);

  useEffect(() => {
    if (powerCurveBase && powerExponent && minSpeed && maxSpeed) {
      getSpeedPowerGraph(selectedVessel?.id);
    }
  }, [powerCurveBase, powerExponent, minSpeed, maxSpeed]);

  return (
    <div className="login-container h-100 splash-screen pb-5">
      <NavbarComponent showfaq={false} showVersion={true} show={true} />
      <main className="container-padding pt-4">
        <div>
          <div className="legislativ-scenario-container ">
            <div className="title">
              <a href={backArrowLink}>
                <button
                  className="btn btn-primary add"
                  onClick={() => handleBackUrl()}
                >
                  <img src={BackArrow} alt="" />
                </button>
              </a>
              <h1 className="mb-0 fs-28 text-dark d-flex">
                {ownerInfo?.owner?.name} Vessel Type Editor
                <span className="beta h-100 bg-primary ms-3">BETA</span>
              </h1>
              <div className="select-scenario d-flex ms-auto align-items-center">
                <p className="input-label mb-0 me-2">Vessel Type</p>
                <SelectBox
                  selectedItem={selectedVessel}
                  listItems={vesselList}
                  setSelectedItem={handleSelectedVesselChange}
                  disabledOptions={[selectedVessel]}
                  showError={false}
                  setShowError={() => { }}
                  showAddElement={false}
                  showPopupOnAddElement={true}
                  addElementOptionName="Add New Vessel"
                  className="select-box-v2"
                  identifier="vesseleditor"
                />
              </div>
            </div>
          </div>
          <div className="d-flex mt-4 gap-4"></div>
          <div className="legislative-main-container justify-content-start col-md-4 d-flex mt-4 w-100 gap-4 align-items-end">
            <div className="row w-100">
              <div className="first-container d-flex align-items-start gap-4 flex-wrap w-100">
                <div className="d-flex gap-4 w-100 vessel-type-details">
                  <div className="gap-1 align-items-center mb-1">
                    <p className="input-label mb-2">Vessel Type Name:</p>
                    <input
                      type="text"
                      name="vesselTypeName"
                      placeholder="Enter Name"
                      value={vesselTypeName || ""}
                      className="vessel-type-editor-input no-highlight"
                      onChange={(e) => {
                        handleChangeVesselTypeName(e);
                      }}
                    />
                    {vesselNameError && (
                      <p className="error text-start mb-0">{vesselNameError}</p>
                    )}
                  </div>
                  <div className="w-35">
                    <p className="input-label mb-2">Vessel Family:</p>
                    <SelectBox
                      selectedItem={selectedVesselFamily}
                      listItems={vesselFamilyList}
                      setSelectedItem={handleChangeVesselFamily}
                      disabledOptions={[selectedVessel]}
                      showError={false}
                      setShowError={() => { }}
                      showAddElement={false}
                      showPopupOnAddElement={true}
                      addElementOptionName="Add New Vessel"
                      className="select-box-v2"
                      identifier="vesseleditor"
                    />
                  </div>
                  <div className="base-year-input">
                    <p className="input-label mb-2">Capacity:</p>
                    <div className="mail-input pos-relative parameter engine-power-input span col w-100">
                      <span className="inside-text">
                        {capitalizeAllLetters(capacityUnit)}
                      </span>
                      <input
                        type="text"
                        className="bg-white no-highlight"
                        placeholder="Enter value"
                        value={capacityValue || ""}
                        onChange={(e) => handleChangeCapacity(e)}
                        onKeyDown={(e) => {
                          handleOnKeyDown(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="mail-input pos-relative parameter vessel-type-editor span input-custom-width">
                    <p className="input-label mb-2">Min Speed:</p>
                    <span className="inside-text mt-1">kt</span>
                    <input
                      type="text"
                      className="bg-white"
                      id="vesselTypeName"
                      placeholder="Enter Speed"
                      value={minSpeed || ""}
                      onChange={(e) => {
                        handleChangeMinSpeed(e);
                      }}
                      onKeyDown={(e) => {
                        handleOnKeyDown(e);
                      }}
                    />
                    {showMinSpeedError && (
                      <p className="error text-start mb-0">
                        {showMinSpeedError}
                      </p>
                    )}
                  </div>
                  <div className="mail-input pos-relative parameter vessel-type-editor span input-custom-width">
                    <p className="input-label mb-2">Max Speed:</p>
                    <span className="inside-text mt-1">kt</span>
                    <input
                      type="text"
                      className="bg-white"
                      id="vesselTypeName"
                      placeholder="Enter Speed"
                      value={maxSpeed || ""}
                      onChange={(e) => {
                        handleChangeMaxSpeed(e);
                      }}
                      onKeyDown={(e) => {
                        handleOnKeyDown(e);
                      }}
                    />
                    {showMaxSpeedError && (
                      <p className="error text-start mb-0">
                        {showMaxSpeedError}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="vessel-type-editor-main flex-lg-wrap flex-xl-nowrap d-flex mt-5 mb-2">
            <div className="col-sm-12 col-xl-8 vessel-type-editor-chart">
              <div className="mb-2">
                <span className="editor-value-title">Power Curve</span>
              </div>
              <div className="col">
                <div className="d-flex gap-2 flex-wrap">
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex gap-2">
                      <p className="mt-2">Power curve base</p>
                      <input
                        type="text"
                        className="vessel-type-editor-input no-highlight"
                        id="vesselTypeName"
                        placeholder="Enter Speed"
                        value={powerCurveBase || ""}
                        onChange={(e) => {
                          handleChangePowerBase(e);
                        }}
                        onKeyDown={(e) => {
                          handleOnKeyDown(e);
                        }}
                      />
                    </div>
                    {powerBaseError && (
                      <p className="error text-end margin-right mb-0">
                        {powerBaseError}
                      </p>
                    )}
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex gap-2">
                      <p className="mt-2">Power curve exponent</p>
                      <input
                        type="text"
                        className="vessel-type-editor-input no-highlight"
                        id="vesselTypeName"
                        placeholder="Enter Speed"
                        value={powerExponent || ""}
                        onChange={(e) => {
                          handleChangePowerExponent(e);
                        }}
                        onKeyDown={(e) => {
                          handleOnKeyDown(e);
                        }}
                      />
                    </div>
                    {powerExpError && (
                      <p className="error text-end margin-right mb-0">
                        {powerExpError}
                      </p>
                    )}
                  </div>
                </div>
                <div className="cii-zfactor-chart mt-3">
                  <div className="chart-bg">
                    <PowerCurveChart chartData={powerChartData} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col vessel-type-editor-details d-xl-block gap-md-3">
              <div className="col vessel-type-editor-value mb-sm-0 mb-xl-3">
                <div className="mb-2">
                  <span className="editor-value-title">
                    Installed Engine Power
                  </span>
                </div>
                <div className="w-100">
                  <div className="w-100">
                    <div className="row mb-2">
                      <div className="col">
                        <strong>Engine Type</strong>
                      </div>
                      <div className="col">
                        <strong>Engine Power</strong>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col">
                        <div className="table-row">M/E:</div>
                      </div>
                      <div className="mail-input pos-relative parameter engine-power-input span col">
                        <span className="inside-text me-2">kW</span>
                        <input
                          type="text"
                          className="bg-white no-highlight"
                          placeholder="Enter value"
                          value={
                            meValue !== null && meValue !== undefined
                              ? meValue
                              : ""
                          }
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, "");
                            handleInputChange({ target: { value } }, "ME");
                          }}
                          onKeyDown={(e) => {
                            const allowedKeyCodes = [8, 37, 39, 46];

                            const isNumberKey = e.key >= "0" && e.key <= "9";
                            const isAllowedKeyCode = allowedKeyCodes.includes(
                              e.keyCode
                            );

                            if (!isNumberKey && !isAllowedKeyCode) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col">
                        <div className="table-row">AUX:</div>
                      </div>
                      <div className="mail-input pos-relative parameter engine-power-input span col">
                        <span className="inside-text me-2">kW</span>
                        <input
                          type="text"
                          className="bg-white no-highlight"
                          placeholder="Enter value"
                          value={
                            auxValue !== null && auxValue !== undefined
                              ? auxValue
                              : ""
                          }
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, "");
                            handleInputChange({ target: { value } }, "AUX");
                          }}
                          onKeyDown={(e) => {
                            const allowedKeyCodes = [8, 37, 39, 46];

                            const isNumberKey = e.key >= "0" && e.key <= "9";
                            const isAllowedKeyCode = allowedKeyCodes.includes(
                              e.keyCode
                            );

                            if (!isNumberKey && !isAllowedKeyCode) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col">
                        <div className="table-row">Boiler:</div>
                      </div>
                      <div className="mail-input pos-relative parameter engine-power-input span col">
                        <span className="inside-text me-2">kW</span>
                        <input
                          type="text"
                          className="bg-white no-highlight"
                          placeholder="Enter value"
                          value={
                            boilerValue !== null && boilerValue !== undefined
                              ? boilerValue
                              : ""
                          }
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, "");
                            handleInputChange({ target: { value } }, "Boiler");
                          }}
                          onKeyDown={(e) => {
                            const allowedKeyCodes = [8, 37, 39, 46];

                            const isNumberKey = e.key >= "0" && e.key <= "9";
                            const isAllowedKeyCode = allowedKeyCodes.includes(
                              e.keyCode
                            );

                            if (!isNumberKey && !isAllowedKeyCode) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col notes-container">
                <div className="mb-2">
                  <span className="editor-value-title">Notes</span>
                </div>
                <div>
                  <textarea
                    className="notes-textarea no-highlight"
                    id="vesselTypeName"
                    placeholder="Your text here..."
                    value={stripHtml(showNotes) || ""}
                    onChange={(e) => handleChangeNotes(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="text-end">
            <button
              className="btn btn-primary btn-lg fs-16"
              onClick={handleSave}
              disabled={isSaveButtonDisabled()}
            >
              Save
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};
export default VesselTypeEditor;
