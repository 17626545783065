import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Loading from "../Loading";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ImoIntensityChart = ({ imoMtmEntries }) => {
  const [labels, setLabels] = useState([]);
  const [dataset, setDataset] = useState([]);

  useEffect(() => {
    if (imoMtmEntries.length > 0) {
      // Extract years and intensity values
      const years = imoMtmEntries.map((entry) => entry.year_);
      const intensities = imoMtmEntries.map((entry) => entry.intensity_gco2eq_MJ);

      setLabels(years);
      setDataset([
        {
          label: "Emissions intensity by year",
          data: intensities,
          borderColor: "#E2C767",
          backgroundColor: "#E2C767",
          tension: 0,
          pointRadius: 1,
          borderWidth: 3,
        },
      ]);
    }
  }, [imoMtmEntries]);

  const chartData = {
    labels: labels,
    datasets: dataset,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: { display: false },
      tooltip: {
        callbacks: {
          label: function (context) {
            return ` ${context.raw.toFixed(2)} [gCO2e/MJ]`;
          },
        },
      },
      legend: {
        display: true,
        position: "bottom",
        align: "center",
        rtl: true,
        labels: {
          usePointStyle: true,
          boxWidth: 10,
          pointStyle: "circle",
          textAlign: "center",
          padding: 20,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "[gCO2e/MJ]",
          color: "#000000",
        },
      },
      x: {
        grid: { display: true },
      },
    },
  };

  return (
    <div className="h-100">
      <div className="chart-label">
        <p>Legislation Under Discussion</p>
      </div>
      {imoMtmEntries.length > 0 ? (
        <Line options={options} data={chartData} />
      ) : (
        <Loading loading={imoMtmEntries.length === 0} height={30} />
      )}
    </div>
  );
};

export default ImoIntensityChart;
